<template>
  <section class="py-6 py-lg-8" ref="policy">
    <div class="container">
      <div class="px-lg-8">
        <h2 class="content-title fs-1 text-center mb-8">投資人聯絡窗口</h2>

        <strong>股務代理機構</strong>
        <p>中國信託商業銀行代理部</p>
        <p>地址：台北市重慶南路一段83號5樓</p>
        <p>電話：(02)6636-5566</p>
        <br />

        <strong>仁大服務窗口</strong>
        <p>發言人：陳宏明 總經理</p>
        <p>電話：(02)7716-7767</p>
        <p>信箱：ir@hpicorp.com.tw</p>
        <br />

        <p>代理發言人：陳欣喬 財務長</p>
        <p>電話：(02)7716-7767</p>
        <p>信箱：ir@hpicorp.com.tw</p>
        <br />

        <p>請將您的意見或問題仁大資訊服務窗口聯繫，我們會儘快向您提供協助。</p>
        <br />

        <strong>投資人服務法律聲明</strong>
        <p class="text_indent">在使用投資人服務相關資訊之前，請務必詳讀本法律聲明。</p>
        <p>&nbsp;</p>
        <p class="text_indent">
          本網站上所有的財務報表、證券櫃檯買賣中心(OTC)申報資料、年報、新聞稿及其他資訊，係本公司根據主管機關要求之提報日期所提供之正確資訊，然可能因時間的經過或後續事件之發生，致使本網站上之資訊不正確或不完整。本公司特此聲明對有時間性之資訊，不負任何更新或修訂之責任。
        </p>
        <p>&nbsp;</p>
        <p class="text_indent">
          本網站上所揭露之資訊可能含有預測性之敘述，惟仍存有許多因素可以使得事件的發展或其實際結果與預測性敘述中的情形有重大差異。本公司特此聲明對預測性敘述，不負任何更新之責任。
        </p>
        <p>&nbsp;</p>
        <p class="text_indent">
          本網站中所揭露之資訊僅為資訊流通之目的而公佈，並非本公司對於買賣任何股票的要約，或要約之引誘。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.new__link {
  text-decoration: underline;
}
.text_indent {
  text-indent: 2rem;
}
p {
  font-weight: 300;
}
</style>
